<template>
  <div id="app" style="background-color: #FFFFFF; height: 100vh;">
    <div>
      <van-image
          width="100vw"
          height="36vh"
          :src="data ? data.bigImage : ''"/>

      <div class="formItem">
        <div class="formItem_title">违规行为</div>
        <div class="formItem_content red">{{
            handleAlarmType(data ? data.alarmType : 0)
          }}
        </div>
      </div>

      <div class="formItem bt">
        <div class="formItem_title">状态</div>
        <div class="formItem_content">
          {{ handleState(data ? data.state : 0) }}
        </div>
      </div>

      <div class="formItem bt">
        <div class="formItem_title">地点</div>
        <div class="formItem_content">{{
            data.business ? data.business.name : '-'
          }}
        </div>
      </div>

      <div class="formItem bt">
        <div class="formItem_title">时间</div>
        <div class="formItem_content" v-if="data.createdTime">
          {{ data.createdTime | moment('yyyy-MM-DD HH:mm:ss') }}
        </div>
        <div class="formItem_content" v-if="!data.createdTime">
          {{ '-' }}
        </div>
      </div>

      <div class="formItem bt">
        <div class="formItem_title">摄像头</div>
        <div class="formItem_content">
          {{ data.kitchenCamera ? data.kitchenCamera.name : '-' }}
        </div>
      </div>

      <div v-if="data.state === 'SOLVED'">
        <div class="formItem bt">
          <div class="formItem_title">处理人</div>
          <div class="formItem_content">{{
              data ? data.handleUser.name : '-'
            }}
          </div>
        </div>

        <div class="formItem bt">
          <div class="formItem_title">处理时间</div>
          <div class="formItem_content">{{
              (data ? data.handleTime : 0) | moment('yyyy-MM-DD HH:mm:ss')
            }}
          </div>
        </div>

        <div class="formItem bt">
          <div class="formItem_title">处理意见</div>
          <!--        <div style="flex: 1; text-align: end;  color: #ff6666;">{{-->
          <!--            data ? data.handleRemark : '-'-->
          <!--          }}-->
          <!--        </div>-->
          <div class="formItem_content">{{ this.handleRemark ? this.handleRemark : '-' }}
          </div>
        </div>
      </div>

      <div class="bank_btn " style="position: absolute; bottom: 1.6vh; width: 100vw;"
           v-if="data.state === 'PENDING' || data.state === 'PROCESSING'">
        <van-button color="#1C8C69" @click="confirm" style="font-size: 16px">确认</van-button>
      </div>
    </div>

    <div class="loading_all" v-if="showProcessRemark">
      <div
          style="width: 100%; background-color: #FFFFFF; margin-left: 12px; margin-right: 12px; border-radius: 4px;">
        <div style="display: flex; padding: 16px 14px 0px 14px">
          <span style="flex: 1; color: #333333; font-size: 16px;">填写处理意见</span>
        </div>

        <div style="margin-top: 16px; padding: 0px 14px 0px 14px">
          <van-field
              v-model="processRemark"
              type="text"
              placeholder="请填写处理意见（非必填）"
              style="height: 92px; border: 1px solid #f1f1f1; border-radius: 4px;"
          />
        </div>

        <div style="display: flex; border-top: 1px solid #F1F1F1; margin-top: 16px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;" @click="showProcessRemark = false">
            取消
          </div>
          <div
              style="flex: 1; height: 40px; line-height: 40px; text-align: center; background-color: #34AC40; color: #FFFFFF; border-radius: 0px 0px 4px 0px"
              @click="onClickConfirm">
            确认
          </div>
        </div>

      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <div v-if="!data && !loading"
         style="display: grid; justify-items: center; position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <img
          style="width: 64px; height: 62px; "
          :src="require('../../../src/assets/images/kitchen/icon_none.png')"
          alt=""/>
      <span style="font-size: 18px; color: #34AC40; margin-top: 10px;">找不到此报警信息</span>
    </div>

  </div>

</template>

<script>
import {post} from "../../utils/httpKitchen";
import {Notify} from "vant";

export default {
  name: "WarningDetailAIBox",
  data() {
    return {
      id: '',
      loading: true,
      showProcessRemark: false,
      data: {
        name: '',
        state: '',
        alarmType: 0
      },
      processRemark: "",
      handleRemark: "",
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.loading = false;
      this.goBack();
      return;
    }

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.fetchDetail();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/kitchen/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    fetchDetail() {
      if (!this.id) {
        this.loading = false;
        return;
      }
      post("api/kitchen/app/v1/alarm/{id}/read".replace("{id}", this.id), {})
          .then(res => {
            if (!res) {
              return;
            }
            this.data = res.data;

            this.handleRemark = '';
            for (const kitchenAlarmUserElement of this.data.kitchenAlarmUser) {
              if (kitchenAlarmUserElement.state === 'SOLVED') {
                this.handleRemark += kitchenAlarmUserElement.handleRemark;
              }
            }
          })
          .catch(err => {
            Notify({type: 'warning', message: err.message});
          })
          .finally(() => {
            this.loading = false;
          })

    },
    handleAlarmType(alarmType) {
      switch (alarmType.toString()) {
        case "6":
          return "违规打电话";
        case "7":
          return "违规吸烟";
        case "31":
          return "未佩戴厨师帽";
        case "32":
          return "鼠患检测";
        case "33":
          return "未佩戴口罩";
        case "34":
          return "未穿厨师服";
      }
      return '-';
    },
    handleState(state) {
      switch (state) {
        case 'PENDING':
          return '待处理';
        case 'PROCESSING':
          return '处理中';
        case 'SOLVED':
          return '已处理';
      }
      return '-';
    },
    confirm() {
      this.processRemark = '';
      // Notify({background: "#A1A1A1", message: "已确认情况"});
      this.showProcessRemark = true;

    },
    onClickConfirm() {
      this.loading = true;
      post('api/kitchen/app/v1/alarm/user/update/handle/remark', {
        kitchenAlarmId: this.id,
        handleRemark: this.processRemark
      })
          .then(res => {
            Notify({background: "#A1A1A1", message: "已确认情况"});
            this.showProcessRemark = false;
            this.processRemark = '';
            this.fetchDetail();
          })
          .catch(err => {
            Notify({type: "danger", message: "处理失败，请重试"});
            this.loading = false;
          })
    },
  }
}
</script>

<style scoped>

.formItem {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 16px;
  margin-right: 16px;
}

.formItem.bt {
  border-top: 1px solid #f1f1f1;
}

.formItem_title {
  color: #A1A1A1;
  margin-right: 16px;
}

.formItem_content {
  flex: 1;
  text-align: end;
  color: #333333;
}

.formItem_content.red {
  color: #ff6666;
}

</style>