import { render, staticRenderFns } from "./AlarmDetail.vue?vue&type=template&id=149e844b&scoped=true&"
import script from "./AlarmDetail.vue?vue&type=script&lang=js&"
export * from "./AlarmDetail.vue?vue&type=script&lang=js&"
import style0 from "./AlarmDetail.vue?vue&type=style&index=0&id=149e844b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149e844b",
  null
  
)

export default component.exports